import { render, staticRenderFns } from "./SkupForma.vue?vue&type=template&id=898b4942&scoped=true&"
import script from "./SkupForma.vue?vue&type=script&lang=js&"
export * from "./SkupForma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "898b4942",
  null
  
)

export default component.exports